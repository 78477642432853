.wrapper {
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  z-index: 11;
}

.root {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  z-index: 2;
  padding: 0 80px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(rgb(164, 164, 164), 30%);
}

.flex1 {
  flex: 1;
}

.button {
  min-width: 160px;
  min-height: 32px;

  background: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  margin: 4px;
}

.userMainStats {
  margin-left: 67px;
}
