.info {
  display: flex;
  justify-content: space-between;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  margin-bottom: 8px;

  color: rgba(255, 255, 255, 0.64)
}

.inputWrapper {
  position: relative;
  width: 100%;
}

.input {
  width: 100%;
  background-color: rgba(0, 43, 91, 0.32);
  border-radius: 8px;
  border: 1px solid white;
  height: 40px;
  color: white;
  font-size: 18px;
  font-weight: 700;
  padding-left: 16px;
  padding-right: 70px;
  outline: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;
}

.inputPostfix {
  position: absolute;
  top: 9px;
  right: 16px;
  font-size: 18px;
  color: white;
}

.amount {
  margin-bottom: 32px;
}

.option {
  color: white;
  background-color: rgba(0, 43, 91);
  font-size: 18px;
  font-weight: 700;
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  & > .opButton {
    width: auto;
  }
}

.opButton {
  padding: 5px 16px;
  font-size: 18px;
  font-weight: 700;
  min-width: 220px;
}

.additionalInfo {
  display: flex;
  position: relative;

  margin: 32px 0 24px;
  padding-bottom: 16px;

  & > :nth-child(2) {
    margin-left: 50px;
  }

  &::before {
    content: '';
    position: absolute;

    height: 1px;

    right: 0;
    left: 0;
    bottom: 0px;
    margin: 0 -20px;

    background-color: rgba(255, 255, 255, 0.24);
  }
}
