.root {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.sidebar {
  min-width: 200px;
}

.mainWrapper {
  flex: 1;
  padding: 0 80px;
}

.mainWrapper {
  position: relative;
}
