.root {
  display: inline-flex;
  flex-wrap: wrap;

  height: 40px;

  margin-bottom: 32px;
  padding: 4px;

  background: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}

.option {
  width: 114px;
  padding: 5px 16px;
  text-align: center;
  border-radius: 8px;
}

.active {
  border: 1px solid #FFD700;
  color: #FFD700;
}

.tab  {
  cursor: pointer;
}
