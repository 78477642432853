.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 32px 0;
  border-radius: 8px;
  flex: 1;
  font-size: 80%;
  background: linear-gradient(269.07deg, rgba(222, 209, 93, 0.24) 0%, rgba(0, 209, 255, 0.08) 100%);
  min-height: 100px;

  &:hover {
    background: linear-gradient(269.07deg, rgba(222, 209, 93, 0.30) 0%, rgba(0, 209, 255, 0.1) 100%);
  };
}

.marketItem {
  padding-bottom: 8px;
}

.market {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 24px 32px 16px;
  cursor: pointer;
}

.position {
  border-top: 1px solid rgba(255, 255, 255, 0.24);
  display: flex;
  padding: 24px 32px 32px;
  align-items: center;
}

.lastElementWrapper {
  display: flex;
  justify-content: end;
  align-items: center;
  min-width: 150px;
}

.details,
.detailsPosition {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  align-items: center;

  & > :nth-child(n) {
    max-width: 125px;
  }
}

.button,
.closeButton {
  min-width: 80px;
}

.closeButton {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  height: 55px;
}

.positionTypeWrapper {
  min-width: 100px;
}

.morePositionInfo {
  flex-direction: column;
}

.itemButton {
  background: none repeat scroll 0 0 transparent;
  border: none;
  border-spacing: 0;
  margin: 0;
  padding: 0;
  text-align: left;
  text-decoration: none;
  text-indent: 0;
  width: 100%;
}

.positionFull {
  display: flex;

  padding: 26px 40px;

  border-top: 1px solid rgba(255, 255, 255, 0.24);
  overflow-x: auto;
}

.detailsPositionFull {
  display: flex;
  flex-direction: column;
  row-gap: 14px;

  width: 200px;

  margin-bottom: 32px;
}

.mainPanel {
  width: 100%;
}

.headerInfo {
  text-align: end;
  margin: 0 4px 8px 0;
  opacity: .8;
}

.mainText {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;

  color: #FFFFFF;
}

.chart {
  height: 265px;
  width: 100%;

  margin-bottom: 45px;

  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.footerInfo {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.marginLevel {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 8px;

  color: #FFFFFF;
}

.explanation {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  color: #FFFFFF;
}

.additionalInfo {
  display: flex;
}

.slider {
  width: 362px;
  align-self: flex-start;
  margin-top: 6px;
}
