.root {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-right: 48px;
  margin-left: 48px;
}

.button {
  min-width: 32px;
  min-height: 32px;
  background: none;
  border-radius: 50%;
  padding: 0;
}

.iconHover {
  &:hover {
    path {
      fill: #FFD700;
    }
    filter: drop-shadow(0px 0px 4px #FFD700);
  }
}

.icon-active-Twitter {
  &:active {
    background-image: url('../../../public/svg/twitter-logo-gradient.svg');
    path {
      fill: none;
    }
  filter: none;
  }
}

.icon-active-Reddit {
  &:active {
    background-image: url('../../../public/svg/reddit-logo-gradient.svg');
    path {
      fill: none;
    }
  filter: none;
  }
}

.icon-active-Github {
  &:active {
    background-image: url('../../../public/svg/github-logo-gradient.svg');
    path {
      fill: none;
    }
  filter: none;
  }
}

.icon-active-Discord {
  &:active {
    background-image: url('../../../public/svg/discord-logo-gradient.svg');
    path {
      fill: none;
    }
  filter: none;
  }
}

.icon-active-Telegram {
  &:active {
    background-image: url('../../../public/svg/telegram-logo-gradient.svg');
    path {
      fill: none;
    }
  filter: none;
  }
}
