.root {
  display: flex;
  margin-left: 24px;
  margin-right: 24px;
}

.cell {
  margin: 0 16px;
  color: #FFD700;
}
