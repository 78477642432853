.heading {
  position: relative;
  margin: 0 0 24px 0;
  color: white;
}

.market {
  color: #FFD700;
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;

  padding: 0;

  cursor: pointer;
}
