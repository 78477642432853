.buttons {
  display: inline-block;
}

.buttons > * {
  margin-right: 5px;
}

.buttons > *:last-child {
  margin-right: 0;
}

.rows {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 5px;
}

* {
  box-sizing: border-box;
}
