.root {
  display: flex;
  align-items: center;

}

.percentEquivalent {
  margin-left: 8px;

  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: #00FF0A;
  white-space: nowrap;
}

.red {
  color: #FB5959;
}

.green {
  color: #00FF0A;
}

.prefix {
  font-weight: 200;
  margin-right: 2px;
}
