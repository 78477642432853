@import '../../../utils/variables';

.root {
  width: 256px;
  background: rgba(255, 255, 255, 0.32);
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: #003686;
  border-radius: 8px;
  color: #fff;
}

.root[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.loading {
  opacity: 0.5;
  cursor: progress;
}

.primary {
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  white-space: nowrap;

  width: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 16px;
  gap: 8px;
  position: relative;

  &:not(:last-child) {
    z-index: 100;
  }

  background: $primary-btn-background;
  border-radius: 8px;

  cursor: pointer;
  transition: $opacity-transition, $transform-transition;
  &:before {
    border-radius: inherit;
    background-image: $primary-hover-btn-background;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    z-index: -100;
    transition: $opacity-transition;
  }
  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    -webkit-transform: scale(1.01);
    -ms-transform: scale(1.01);
    transform: scale(1.01);
    &:before {
      opacity: 1;
    }
  }
}

.secondary {
  width: fit-content;
  background: transparent;
  padding: 5px 16px;
}
