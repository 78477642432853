.slider {
  margin-top: 20px;
  width: 100%;
  height: 20px;
}

.thumb {
  background: white;
  width: 8px;
  height: 20px;
  border-radius: 4px;
  top: 0px;
}

.thumbValue {
  position: absolute;
  top: -20px;
}

.track {
  background: linear-gradient(270deg, #00CCFF 0%, #FFD700 100%);
  height: 12px;
  border-radius: 6px;
  width: 100%;
  top: 4px;
}

.marksWrapper {
  position: relative;
  padding-top: 8px;
  padding-bottom: calc(12px * 1.21);
}

.customMarkWrapper {
  display: flex;
  justify-content: center;
  position: absolute;
  color: white;
}

.customMarkWrapper1 {
  left: 0;
  width: 66.7%;
}

.customMarkWrapper2 {
  left: 33.3%;
  width: 66.7%;
}

.customMark {
  font-size: 12px;
  font-weight: 700;
  line-height: 1.21;
  color: white;
}
