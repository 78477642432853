.root {
  display: flex;
  align-items: center;
}

.dollarEquivalent {
  margin-left: 8px;

  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: rgba(255, 255, 255, 0.64);

  white-space: nowrap;
}

.prefix {
  font-weight: 200;
  margin-right: 2px;
}

.suffix {
  font-weight: 200;
  margin-left: 2px;
}
