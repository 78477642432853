.root {
  color: #00FF0A;
}

.red {
  color: #FB5959;
  font-weight: bod;
}

.suffix {
  font-weight: 200;
  margin-left: 2px;
}
