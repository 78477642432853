.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  column-gap: 32px;
}

.title {
  color: #fff;
  margin: 32px 64px 0 32px;
  padding: 0;
  line-height: 40px;
  font-size: 32pt;
}
